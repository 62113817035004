import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import config from 'config';
import mixpanel from 'mixpanel-browser';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

if (config.sentry) {
  Sentry.init({
    dsn: config.sentry.dsn,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

if (config.mixpanel) {
  (async () => {
    mixpanel.init(config.mixpanel.secret);

    const slug = window.location.pathname.replace('/', '');
    const host = window.location.hostname;
    mixpanel.register({ host, slug });

    if(config.fingerprint) {
      // We recommend to call `load` at application startup.
      const fp = await FingerprintJS.load();
    
      // The FingerprintJS agent is ready.
      // Get a visitor identifier when you'd like to.
      const result = await fp.get();
    
      // This is the visitor identifier:
      const visitorId = result.visitorId;
      console.log(visitorId);

      mixpanel.identify(visitorId)
    }
  })();
}


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
