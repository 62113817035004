import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { saveAs } from 'file-saver';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { track } from './utils/events';

function GalleryView({ gallery }) {
  // const [isLoading, setLoading] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const { photos } = gallery;

  function download() {
    const url = photos[currentImageIndex].src;    
    const parts = url.split('/');
    const name = parts[parts.length - 1];
    saveAs(url, name);

    track('download_single', { name });
  }
  const toolbarButtons = [
    <button class="text-gray-100 leading-7 inline-flex items-center">
      <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
      <span onClick={download}>Download</span>
    </button>
  ];
  const rowHeight = 300;
  return (
    <>
        {currentImageIndex !== null &&
          <Lightbox
            mainSrc={photos[currentImageIndex].large}
            nextSrc={photos[(currentImageIndex + 1) % photos.length]}
            prevSrc={photos[(currentImageIndex + photos.length - 1) % photos.length]}
            onCloseRequest={() => setCurrentImageIndex(null)}
            onMovePrevRequest={() => {
              track('prev_photo')
              setCurrentImageIndex((currentImageIndex + photos.length - 1) % photos.length)
            }}
            toolbarButtons={toolbarButtons}
            enableZoom={false}
            onMoveNextRequest={() => {
              track('next_photo')
              setCurrentImageIndex((currentImageIndex + 1) % photos.length)
            }}
          />
        }
        
        <section>
        {photos.map((image, index) => (
          <div className="image-wrap" id={`photo-${image.id}`} style={{ width: image.thumbnailWidth*rowHeight/image.thumbnailHeight, flexGrow: image.thumbnailWidth*rowHeight/image.thumbnailHeight}}>
            <i style={{paddingBottom: (image.thumbnailHeight/image.thumbnailWidth*100) + '%'}}></i>
            <LazyLoadImage
              src={image.large}
              placeholderSrc={image.placeholder}
              effect="blur"
              class="mb-2"
              onClick={() => {
                track('view_photo');
                setCurrentImageIndex(index);
              }}
              key={index}
              alt=""
              threshold={300}
            />
          </div>
         
        ))}
        </section>        
    </>
  );
}

export default GalleryView;
