import axios from 'axios';
import config from 'config';

export const getGallery = async (slug, userId) => {
  const res = await axios({
    url: `${config.apiHost}/galleries?slug=${slug}&user=${userId}`,
    method: 'get',
  });

  return res.data[0];
};

export const getUserByCname = async (host) => {
  const res = await axios({
    url: `${config.apiHost}/cnames?host=${host}`,
    method: 'get',
  });

  return res.data[0].user;
};

