import React, { useEffect, useState } from 'react';
import Gallery from './Gallery';
import { getUserByCname, getGallery } from './utils/api';
import './App.css';
import { track } from './utils/events';

function App() {
  const slug = window.location.pathname.replace('/', '');
  const host = window.location.hostname;

  const [user, setUser] = useState(null);
  const [gallery, setGallery] = useState(null);

  useEffect(() => {
    getUserByCname(host).then((data) => {
      console.log('userId', data);
      setUser(data);

      getGallery(slug, data.id).then(galleryData => {
        console.log('gallery', galleryData);
        const { sortOrder, photos } = galleryData;

        let sortedIds = [];
      
        if(sortOrder && sortOrder.length && sortOrder.length === photos.length) {
          sortedIds = sortOrder;        
        } else {        
          sortedIds = photos.map(p => p.id);
        }

        let images = [];
        sortedIds.forEach(photoId => {
          const photo = photos.find(p => p.id === photoId);

          if(photo) {
            images.push({
              id: photo.id,
              order: photo.order,
              src: photo?.file?.url,
              large: photo?.file?.formats?.large?.url,
              placeholder: photo?.file?.formats?.thumbnail?.url,
              thumbnail: photo?.file?.formats?.small?.url,
              thumbnailWidth: photo?.file?.formats?.small?.width,
              thumbnailHeight: photo?.file?.formats?.small?.height,
              width: photo?.file?.formats?.large?.width,
              height: photo?.file?.formats?.large?.height
            });
          }
        })

        galleryData.photos = images;
        setGallery(galleryData);
      })
    })

    track('page_view');
  }, [host, slug]);

  function downloadAll() {
    track('download_all');
    document.location.href = gallery.downloadUrl;
  }

  function onGallertSort(images) {

  }

  if(!user || !gallery) return 'Loading...';

  let images = [];
  // if (gallery.photos && gallery.photos.length) {
    
  //   // images.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)); 
  // }
  

  return (
    <div class="font-sans py-4 bg-white" id="top">
      <div class="mx-auto">
        <div class="text-center px-4">
          <h2 class="mt-0 text-1xl font-extrathin text-gray-900">
            {user.title}
          </h2>

          <h3 class="mt-5 text-5xl font-logo leading-8 font-extrabold text-gray-90">
            {gallery.title}
          </h3>
          <p class="mt-4 max-w-2xl mx-auto text-xl  leading-7 text-gray-500 pl-2">
            {gallery.description}
          </p>
        </div>
        
        <div class="mt-6 top-banner" style={{ backgroundImage: `url(${gallery.banner.file.url})`}}></div>

        <div class="mt-6 lg:mr-32 lg:ml-32 px-4">
          <div className="float-right flex-wrap mt-2 mb-2">
            <button class="text-gray-800 py-1 px-4 rounded-l inline-flex items-center" onClick={downloadAll}>
              <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
              <span>Download all</span>
            </button>
          </div>

          <div style={{ clear: 'both' }}></div>

          <Gallery
            gallery={gallery}
            images={images}
            onSort={onGallertSort}
          />
        </div>
      </div>

      <a href="#top" class="fixed bottom-0 right-0 mr-5 mb-5">
        <svg class="h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
        </svg>
      </a>
    </div>

  );
}

export default App;
